#transferFunds {
  .form_box {
    .top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 15%;
    }

    p {
      font-size: 14px;
      line-height: 1.64;
      color: $primary-purple;
      margin-bottom: 15px;

      a {
        color: $primary-purple;
        text-decoration: underline;
      }
    }

    .form_button {
      text-align: center;
    }
  }
}

@media (max-width: 1200px) {
  #transferFunds .form_box .top {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 550px) {
  #transferFunds .form_box {
    padding: 45px 24px 24px;
    .top {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}
